<template>
    <tr>
        <!-- 項目名 -->
        <td 
            ref="jest-th-name"
            v-if="name"
            :colspan="name_colspan"
            :rowspan="name_rowspan">
            {{ name }}
        </td>
        <td ref="jest-th-type"
            v-if="name_colspan < 2 && type"
            :colspan="type_colspan"
            :rowspan="type_rowspan">
            {{ type }}
        </td>
        <td ref="jest-th-algebra"
            v-if="name_colspan < 3 && algebra">
            {{ algebra }}
        </td>
        <!-- データ -->
        <td
            class="td_data"
            ref="jest-th-value">
            {{ value }}
        </td>
    </tr>
</template>

<script>
export default {
    name: 'DeviceTableRow',
    props: {
        // 項目名1列目の要素
        name: {
            type: String,
            default: null
        },
        // 項目名2列目の要素
        type: {
            type: String,
            default: null
        },
        // 項目名3列目の要素
        algebra: {
            type: String,
            default: null
        },
        // データ部分の要素
        value: {
            type: [String, Number, Boolean],
            default: '-'
        },

        // 項目名1列目のcolspan
        name_colspan: {
            type:[Number,null],
            default: 1
        },
        // 項目名1列目のrowspan
        name_rowspan: {
            type: [Number,null],
            default: 1
        },
        // 項目名2列目のcolspan
        type_colspan: {
            type: [Number,null],
            default: 1
        },
        // 項目名2列目のrowspan
        type_rowspan: {
            type: [Number,null],
            default: 1
        },
    }
}
</script>

<style scoped>
.td_data{
    max-width: 20em;
}
</style>