export default {
    methods: {
        //base64形式の文字列をblobに変換
        toBlob(base64) {
            const bin = atob(base64.replace(/^.*,/, ""))
            const buffer = new Uint8Array(bin.length)
            for (let i = 0; i < bin.length; i++) {
                buffer[i] = bin.charCodeAt(i)
            }
            // Blobを作成
            let blob = false
            try {
                blob = new Blob([buffer.buffer], {
                    type: "application/pdf",
                })
            } catch (e) {
                return blob
            }
            return blob
        },

        /*
         *  BASE64エンコーディングされたPDFファイルを別タブで開く
         */
        openPdf(file, filename) {
            //ダウンロード用のBlobを作成
            const blob = this.toBlob(file)
            if (navigator.msSaveOrOpenBlob) {
                // IE対応(EdgeもmsSaveOrOpenBlobに対応しているためこちらで処理されます)
                navigator.msSaveOrOpenBlob(blob, filename)
            } else {
                // a要素の作成
                let link = document.createElement("a")
                // ダウンロード対象にBlobを指定
                link.href = window.URL.createObjectURL(blob)
                // 別タブで開くように指定
                link.target = "_blank"
                // 実在する要素(非表示でよい)の子要素としてa要素を追加しないとChrome以外で動かない
                document.getElementById("invisible").appendChild(link)
                link.click()
                document.getElementById("invisible").removeChild(link)

            }
        }
    }
}